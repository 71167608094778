body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-wrapper p {
  padding: 0;
  margin: 0;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
/* 详细页图片或框框的样式;
*/
.home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-wrapper .home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-wrapper .title-wrapper > h1,
.home-page-wrapper > h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 16px;
}
.home-page-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-wrapper .home-page {
    padding: 56px 24px;
  }
  .home-page-wrapper .home-page > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-wrapper .home-page > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-wrapper .home-page > p {
    margin-bottom: 32px;
  }
}
.header2 {
  background: #001529;
  width: 100%;
  z-index: 1;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
  position: relative;
  top: 0;
}
.header2 .home-page {
  padding: 0 24px;
}
.header2-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header2-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header2-logo a {
  display: block;
}
.header2-menu {
  float: right;
}
.header2-menu > .menu-item {
  line-height: 62px;
  display: inline-block;
  padding: 0 20px;
  cursor: pointer;
  background: transparent;
  color: #fff;
  height: 64px;
  border-bottom-color: transparent;
  position: relative;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.header2-menu > .menu-item.active,
.header2-menu > .menu-item:hover {
  color: #1890ff;
  border-bottom: 2px solid #1890ff;
}
@media screen and (max-width: 767px) {
  .header2-logo {
    z-index: 101;
  }
  .header2.home-page-wrapper .home-page {
    padding: 0 24px;
  }
  .header2-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header2-menu .menu-item {
    display: block;
    width: 100%;
    padding: 0 24px;
    border-bottom: none;
    line-height: 40px;
    height: 40px;
    margin: 4px 0 8px;
  }
  .header2-menu .menu-item.active,
  .header2-menu .menu-item:hover {
    border: none;
    background: #1890ff;
    color: #fff;
  }
  .header2-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header2-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 4px;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header2-mobile-menu :first-child {
    margin-top: 0;
  }
  .header2 .open {
    height: auto;
  }
  .header2 .open .header2-mobile-menu em:nth-child(1) {
    -webkit-transform: translateY(6px) rotate(45deg);
            transform: translateY(6px) rotate(45deg);
  }
  .header2 .open .header2-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header2 .open .header2-mobile-menu em:nth-child(3) {
    -webkit-transform: translateY(-6px) rotate(-45deg);
            transform: translateY(-6px) rotate(-45deg);
  }
  .header2 .open .header2-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
.banner3 {
  width: 100%;
  height: 100vh;
  position: relative;
  text-align: center;
  border-color: #666;
  background-image: url("https://gw.alipayobjects.com/zos/rmsportal/xTPkCNNLOnTEbGgVZOpE.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.banner3 .banner3-text-wrapper {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  color: #fff;
  max-width: 845px;
  height: 500px;
  width: 80%;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}
.banner3 .banner3-text-wrapper > .queue-anim-leaving {
  position: relative !important;
}
.banner3 .banner3-slogan {
  font-size: 68px;
  line-height: 80px;
  text-indent: 2px;
  font-weight: 600;
  margin: 26px auto 38px;
  overflow: hidden;
}
.banner3 .banner3-name-en {
  display: block;
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
}
.banner3 .banner3-name {
  font-size: 24px;
  overflow: hidden;
  opacity: 0.8;
}
.banner3 .banner3-button {
  display: block;
  margin: 72px auto 0;
  background: #034365;
  background: linear-gradient(to right, #034365 0%, #001b33 100%);
  box-shadow: 0 8px 16px #0a52ab;
  border: none;
  transition: background 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 132px;
  line-height: 42px;
  height: 42px;
  border-radius: 42px;
}
.banner3 .banner3-time {
  font-size: 14px;
  line-height: 24px;
  margin-top: 24px;
}
@media screen and (max-width: 767px) {
  .banner3 {
    background-attachment: inherit;
  }
  .banner3 .banner3-text-wrapper {
    width: 90%;
    height: 50%;
  }
  .banner3 .banner3-name-en {
    font-size: 12px;
  }
  .banner3 .banner3-slogan {
    font-size: 24px;
    line-height: 1.5;
    margin: 12px 0;
  }
  .banner3 .banner3-name {
    font-size: 14px;
  }
}
.content8-wrapper.home-page-wrapper {
  overflow: initial;
  width: calc(100% - 112px);
  min-height: 878px;
  margin: auto;
  border-radius: 4px;
  box-shadow: 0 32px 32px rgba(34, 94, 222, 0.08);
  background: #fff;
}
.content8-wrapper.home-page-wrapper .home-page {
  margin-top: -220px;
  padding: 64px 24px;
  overflow: hidden;
}
.content8-wrapper .content-wrapper {
  margin-top: 72px;
}
.content8-wrapper .content-wrapper .content8-block-wrapper {
  margin-bottom: 60px;
}
.content8-wrapper .content-wrapper .content8-block {
  width: 100%;
  max-width: 192px;
  margin: auto;
}
.content8-wrapper .content-wrapper .content8-img {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}
.content8-wrapper .content-wrapper .content8-img img {
  width: 100%;
  display: block;
}
.content8-wrapper .content-wrapper .content8-img img[src=""] {
  background: linear-gradient(to top right, #d6defc, #fff);
  padding-bottom: 100%;
}
.content8-wrapper .content-wrapper .content8-title {
  font-size: 20px;
  color: #0d1a26;
  font-weight: 400;
  margin: 24px auto 8px;
  text-align: center;
  white-space: nowrap;
}
.content8-wrapper .content-wrapper .content8-content {
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  color: #697b8c;
}
@media screen and (max-width: 767px) {
  .content8-wrapper.home-page-wrapper {
    padding-bottom: 0;
    box-shadow: none;
    width: 100%;
  }
  .content8-wrapper.home-page-wrapper .content8.home-page {
    margin: auto;
    padding-bottom: 0;
  }
  .content8-wrapper.home-page-wrapper .content-wrapper .content8-block {
    max-width: 240px;
  }
}
.feature8-wrapper {
  min-height: 680px;
  margin: 0 auto;
  overflow: hidden;
  background-color: rgba(253, 253, 253, 0.3);
  background-image: linear-gradient(360deg, rgba(193, 218, 255, 0.3) 0%, rgba(253, 253, 253, 0.3) 80%);
}
.feature8-wrapper.home-page-wrapper .home-page {
  padding: 64px 24px;
}
.feature8-title-wrapper {
  text-align: center;
  margin-bottom: 40px;
}
.feature8-title-h1 {
  font-size: 32px;
  color: rgba(0, 0, 0, 0.85);
}
.feature8-title-content {
  margin-top: 16px;
}
.feature8-carousel {
  width: calc(100% + 72px);
  margin-left: -36px;
}
.feature8-carousel-title {
  font-size: 20px;
  text-align: center;
}
.feature8-carousel-title-block {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.45s;
  padding: 0 40px;
  position: relative;
  cursor: pointer;
}
.feature8-carousel-title-block.active {
  color: rgba(0, 0, 0, 0.85);
  cursor: auto;
}
.feature8-carousel-title-block::before {
  display: block;
  content: '';
  width: 1px;
  height: 70%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: #d8d8d8;
}
.feature8-carousel-title-block:last-child::before {
  display: none;
}
.feature8-block {
  margin-top: 48px;
}
.feature8-block-wrapper {
  margin-top: 32px;
}
.feature8-block-row {
  padding: 0 36px;
}
.feature8-block-col {
  text-align: center;
  margin: 36px 0;
  position: relative;
}
.feature8-block-col:last-child .feature8-block-arrow {
  display: none;
}
.feature8-block-child {
  padding: 36px 24px 24px;
  border-radius: 6px;
  height: 100%;
  transition: box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.feature8-block-child:hover {
  background-color: #f6f9ff;
  box-shadow: 0 12px 36px rgba(100, 100, 136, 0.15);
}
.feature8-block-child:hover .feature8-block-content {
  opacity: 1;
}
.feature8-block-image {
  display: inline-block;
}
.feature8-block-title {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 16px;
}
.feature8-block-content {
  opacity: 1;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 36px;
  text-align: left;
  transition: opacity 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.feature8-block-arrow {
  position: absolute;
  right: 0;
  width: 16px;
  top: 80px;
}
.feature8-button {
  text-align: center;
}
.feature8-button-wrapper {
  margin-top: 16px;
}
.feature8-button .ant-btn {
  padding: 8px 40px;
  border-radius: 20px;
  line-height: 1.5;
  height: 40px;
  border: none;
  color: #fff;
  background-color: #3c89f6;
  background-image: linear-gradient(135deg, #82b5ff 0%, #3c89f6 100%);
  box-shadow: 0 9px 22px rgba(95, 155, 241, 0.35);
  transition: color 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), box-shadow 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform, box-shadow;
}
.feature8-button .ant-btn:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: 0 14px 26px rgba(95, 155, 241, 0.4);
}
@media screen and (max-width: 767px) {
  .feature8-wrapper {
    padding-bottom: 0;
    min-height: 1540px;
  }
  .feature8-wrapper.home-page-wrapper .home-page {
    padding: 56px 24px 64px;
  }
  .feature8-carousel-title {
    font-size: 16px;
  }
  .feature8-carousel-title-block {
    padding: 0 8px;
  }
  .feature8-block-child {
    width: 80%;
    margin: auto;
    background-color: #f6f9ff;
    box-shadow: 0 12px 36px rgba(100, 100, 136, 0.15);
  }
  .feature8-block-content {
    text-align: center;
  }
  .feature8-block-arrow {
    bottom: -40px;
    top: auto;
    left: 0;
    right: 0;
    margin: auto;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}
.content4-wrapper {
  min-height: 720px;
  background: #fafafa;
}
.content4-wrapper .content4 {
  height: 100%;
  overflow: hidden;
}
.content4-wrapper .content4-video {
  border-radius: 4px;
  overflow: hidden;
  max-width: 800px;
  margin: auto;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
.content4-wrapper .content4-video video {
  display: block;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .content4-wrapper {
    min-height: 350px;
  }
  .content4-wrapper .content4 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .content4-wrapper .content4-video {
    top: 15%;
    background: url("https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg") no-repeat center;
    background-size: cover;
  }
}
.teams1-wrapper {
  min-height: 446px;
  overflow: hidden;
}
.teams1-wrapper .teams1 {
  overflow: hidden;
  height: 100%;
  padding: 64px 24px;
}
.teams1-wrapper .teams1 > .title-wrapper {
  margin: 0 auto 48px;
}
.teams1-wrapper .teams1 .block-wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: 20px 0;
}
.teams1-wrapper .teams1 .block-wrapper .block {
  display: inline-block;
  text-align: center;
  margin-bottom: 48px;
}
.teams1-wrapper .teams1 .block-wrapper .block.queue-anim-leaving {
  position: relative !important;
}
.teams1-wrapper .teams1-image,
.teams1-wrapper .teams1-title,
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  width: 200px;
  margin: auto;
  line-height: 1.5;
}
.teams1-wrapper .teams1-image {
  color: #404040;
}
.teams1-wrapper .teams1-image img {
  width: 100%;
}
.teams1-wrapper .teams1-title {
  font-size: 24px;
  margin: 24px auto 8px;
}
.teams1-wrapper .teams1-job {
  margin: 8px auto;
}
.teams1-wrapper .teams1-job,
.teams1-wrapper .teams1-content {
  font-size: 12px;
  color: #919191;
}
@media screen and (max-width: 767px) {
  .teams1-wrapper {
    min-height: 1440px;
  }
}
.content8-wrapper.home-page-wrapper {
  overflow: initial;
  width: calc(100% - 112px);
  min-height: 878px;
  margin: auto;
  border-radius: 4px;
  box-shadow: 0 32px 32px rgba(34, 94, 222, 0.08);
  background: #fff;
}
.content8-wrapper.home-page-wrapper .home-page {
  margin-top: -220px;
  padding: 64px 24px;
  overflow: hidden;
}
.content8-wrapper .content-wrapper {
  margin-top: 72px;
}
.content8-wrapper .content-wrapper .content8-block-wrapper {
  margin-bottom: 60px;
}
.content8-wrapper .content-wrapper .content8-block {
  width: 100%;
  max-width: 192px;
  margin: auto;
}
.content8-wrapper .content-wrapper .content8-img {
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}
.content8-wrapper .content-wrapper .content8-img img {
  width: 100%;
  display: block;
}
.content8-wrapper .content-wrapper .content8-img img[src=""] {
  background: linear-gradient(to top right, #d6defc, #fff);
  padding-bottom: 100%;
}
.content8-wrapper .content-wrapper .content8-title {
  font-size: 20px;
  color: #0d1a26;
  font-weight: 400;
  margin: 24px auto 8px;
  text-align: center;
  white-space: nowrap;
}
.content8-wrapper .content-wrapper .content8-content {
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  color: #697b8c;
}
@media screen and (max-width: 767px) {
  .content8-wrapper.home-page-wrapper {
    padding-bottom: 0;
    box-shadow: none;
    width: 100%;
  }
  .content8-wrapper.home-page-wrapper .content8.home-page {
    margin: auto;
    padding-bottom: 0;
  }
  .content8-wrapper.home-page-wrapper .content-wrapper .content8-block {
    max-width: 240px;
  }
}
.footer0-wrapper {
  background-color: #001529;
  height: 80px;
  overflow: hidden;
}
.footer0-wrapper .footer0 {
  height: 100%;
  padding: 0 24px;
  line-height: 80px;
  text-align: center;
  color: #999;
  position: relative;
}
@media screen and (max-width: 767px) {
  .footer0-wrapper .footer0 {
    font-size: 12px;
  }
  .footer0-wrapper .footer0.home-page {
    padding: 0;
  }
  .footer0-wrapper .footer0 > div {
    width: 90%;
    margin: auto;
  }
}
div > .templates-wrapper > .jrhtw9ph4a-editor_css {
  position: absolute;
  background-color: rgba(0, 21, 41, 0);
  box-shadow: none;
}
.home-page > .header2-menu > .active {
  color: #ffffff;
}
.home-page > .header2-menu > .menu-item:hover {
  color: #e0e7ff;
  border-bottom-color: #ffffff;
}
#Banner3_0 .banner3-text-wrapper > .l1et5z46c2r-editor_css {
  font-size: 30px;
}
#Content8_0 div > .ant-row > .l1etxi4xtz9-editor_css {
  height: 100%;
}
#Banner3_0 .banner3-text-wrapper > .banner3-button {
  background-color: #ffffff;
  background-image: ;
  background-attachment: ;
  background-blend-mode: ;
  background-position: ;
  background-repeat: ;
  background-size: ;
  background-clip: ;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0);
}
#Nav2_0.jrhtw9ph4a-editor_css {
  top: 30px;
}
#Nav2_0 .l1et397jjm-editor_css {
  top: 0px;
}
#Nav2_0 .home-page > .header2-logo {
  left: 0;
}
#Nav2_0 .home-page > .l1est5zaj6e-editor_css {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  top: 0px;
  left: 0px;
  border-radius: 0;
}
#Banner3_0 .banner3-text-wrapper > .l1etd91ayb-editor_css {
  background-clip: ;
  font-size: 25px;
  font-weight: 500;
  line-height: 60px;
  color: #108ee9;
  width: 232px;
  height: 60px;
  background-color: #ffffff;
  background-image: radial-gradient(circle at center center, #ffffff 0%, white 100%);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0);
}
#Banner3_0 .banner3-text-wrapper > .l1etd91ayb-editor_css:hover {
  line-height: 60px;
  width: 232px;
  height: 60px;
  background-color: #ffffff;
  background-image: ;
  background-attachment: ;
  background-blend-mode: ;
  background-position: ;
  background-repeat: ;
  background-size: ;
  background-clip: ;
}
#Banner3_0 .banner3-text-wrapper > .l1etd91ayb-editor_css:active {
  line-height: 60px;
  width: 232px;
  height: 60px;
  background-color: #ffffff;
  background-image: ;
  background-attachment: ;
  background-blend-mode: ;
  background-position: ;
  background-repeat: ;
  background-size: ;
  background-clip: ;
}
#Banner3_0 .banner3-text-wrapper > .l1etd91ayb-editor_css:focus {
  line-height: 60px;
  width: 232px;
  height: 60;
  background-color: #ffffff;
  background-image: ;
  background-attachment: ;
  background-blend-mode: ;
  background-position: ;
  background-repeat: ;
  background-size: ;
  background-clip: ;
}
#Teams1_0 .ant-row > .ant-col > .teams1-image {
  height: 200px;
}
#Teams1_0 .ant-row > .ant-col > .teams1-job {
  font-size: 16px;
}
#Teams4_0 .ant-row > .ant-col > .content8-block {
  display: block;
  align-items: center;
  justify-content: center;
  text-align: center;
}
#Content8_0 .ant-col > .content8-block > .content8-title {
  font-family: Hiragino Sans GB;
  font-weight: 500;
  color: #919191;
}
#Content8_0 .ant-col > .content8-block > .content8-img {
  height: 120px;
}
#Content8_0 div > div > .l1fvpheccrr-editor_css {
  margin: 0px 0 0;
}
#Teams4_0 .l1fuillvdib-editor_css {
  min-height: 600px;
  margin: 0 auto auto;
}
#Feature8_0 .ant-col > .feature8-block-child > .feature8-block-image {
  overflow: hidden;
  width: 100px;
  height: 100px;
}
#Nav2_0 .home-page > .header2-menu > .l1etzupuj1n-editor_css {
  font-size: 20px;
}
#Content8_0.l1fv7kuf6e-editor_css {
  height: 120px;
  min-height: 750px;
  margin: -220px auto auto;
}
#Feature8_0 div > div > .l1g0jo5058l-editor_css {
  margin: atuo;
}
#Nav2_0 .home-page > .header2-menu > .menu-item {
  font-size: 20px;
}
#Content4_0 .home-page > .title-wrapper > .l1fvrkqsf-editor_css {
  font-size: 22px;
  margin: 0px 100px;
}
#Banner3_0 .banner3-text-wrapper > .l1ethneipc7-editor_css {
  margin: 88px 0 0;
}
#Teams4_0.l1furhg3abs-editor_css {
  width: 100%;
  min-height: 800px;
}
#Teams4_0 .ant-col > .content8-block > .content8-img {
  width: 80px;
  height: 100px;
  margin: 0px auto;
}
#Teams4_0 .ant-col > .content8-block > .content8-title {
  font-size: 16px;
  margin: 12px auto 0px;
}
#Teams4_0 div > div > .l1g2smw46t-editor_css {
  width: auto;
  height: auto;
}

